import api from "../axios_service.js";

export async function fetchServicosGrupos(filtros) {
  const response = await api.get(`/gestor/servicos-grupos${filtros ? filtros : ""}`);
  return response.data.result;
}

// Busca um servico
export async function fetchServicoGrupo(id) {
  const response = await api.get(`/gestor/servicos-grupos/${id}`);
  return response.data.result;
}

export function putServicoGrupo(id, grupo) {
  let body = {};
  for (let key in grupo) {
    body[key] = grupo[key];
  }
  return api.put(`/gestor/servicos-grupos/${id}`, body);
}

export function postServicoGrupo(grupo) {
  let body = {};
  for (let key in grupo) {
    body[key] = grupo[key];
  }
  return api.post("/gestor/servicos-grupos/add", body);
}
