<template>
  <v-dialog
    v-model="dialogServico"
    @click:outside="$emit('update:dialogServico', false)"
    @keydown.esc="$emit('update:dialogServico', false)"
    scrollable
    width="500"
  >
    <v-card tile :loading="loading">
      <v-card-title class="pa-4 font-weight-bold accent">
        {{ add ? "Adicionar" : "Editar" }} Serviço
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogServico', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-5 py-6">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <!--status -->
            <v-col cols="12" v-if="!add">
              <v-text-field dense readonly value="Status:">
                <template v-slot:append>
                  <v-switch
                    v-model="servico.status"
                    :false-value="0"
                    :true-value="1"
                    class="mt-0 pt-0"
                    hide-details
                  >
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>

            <!-- Serviço -->
            <v-col cols="12" md="12">
              <v-text-field
                v-model="servico.descricao"
                label="Serviço"
                :rules="[formRules]"
              >
              </v-text-field>
            </v-col>

            <!-- Grupo Pertencente -->
            <v-col cols="12" md="12">
              <v-combobox
                v-model="selectedGrupo"
                ref="grupoBox"
                :items="grupos"
                :search-input.sync="buscaGrupo"
                return-object
                hide-selected
                label="Grupo Pertencente"
                item-text="descricao"
                item-value="id"
                item-color="secondary"
                clearable
                :disabled="loadingGrupos"
                :rules="[formRules]"
              >
                <template v-slot:no-data>
                  <v-list-item v-if="!buscaGrupo">
                    nenhum grupo encontrado
                  </v-list-item>
                  <v-list-item v-else @click="addGrupo" class="my-n2 py-1">
                    <span class="caption">Clique para adicionar </span>
                    <v-sheet
                      label
                      color="accent"
                      rounded=""
                      class="px-3 py-1 ml-2 text-body-2"
                    >
                      {{ buscaGrupo }}
                    </v-sheet>
                  </v-list-item>
                </template>

                <template v-slot:item="{ index, item }">
                  <v-text-field
                    v-model="item.descricao"
                    v-if="item.edit"
                    @click.stop
                    append-icon="mdi-content-save"
                    @click:append="updateGrupo(item)"
                  ></v-text-field>
                  <template v-else>
                    {{ item.descricao }}
                  </template>

                  <v-spacer></v-spacer>
                  <v-list-item-action>
                    <v-btn icon small @click.stop.prevent="item.edit = true">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-combobox>
            </v-col>

            <!-- Unidade Padrão -->
            <v-col cols="12" md="6">
              <v-select
                v-model="servico.unidade"
                :items="['hora']"
                label="Unidade Padrão"
              ></v-select>
            </v-col>

            <!-- Valor Padrão-->
            <v-col cols="12" md="6">
              <CurrencyField
                label="Valor Padrão"
                :valor_sync.sync="servico.valor"
                moeda="R$"
                :precision="2"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn @click="$emit('update:dialogServico', false)">
          {{ add ? "cancelar" : "voltar" }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!validForm"
          @click="add ? createServico() : updateServico()"
          color="secondary"
          class="white--text"
          depressed
        >
          {{ add ? "Adicionar" : "Salvar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { postServico, putServico } from "@/api/gestor/servicos.js";
import { inputRequired } from "@/helpers/utils.js";
import {
  fetchServicosGrupos,
  postServicoGrupo,
  putServicoGrupo,
} from "@/api/gestor/servicos_grupos.js";
export default {
  name: "NovoPrestado",

  props: {
    dialogServico: {
      type: Boolean,
      required: true,
    },
    add: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
  },

  components: {
    CurrencyField: () =>
      import("../../../../components/fields/CurrencyField.vue"),
  },

  data() {
    return {
      validForm: true,
      loading: false,
      servico: {},
      dialogGrupo: false,
      grupos: [],
      selectedGrupo: null,
      buscaGrupo: "",
      loadingGrupos: false,
      formRules: inputRequired,
    };
  },

  watch: {
    selectedGrupo() {
      if (this.selectedGrupo) {
        this.servico.grupo_id = this.selectedGrupo.id;
      }
    },
  },

  methods: {
    getGrupos() {
      this.loadingGrupos = true;
      return fetchServicosGrupos()
        .then((response) => {
          const grupos = response.map((item) => {
            item.edit = false;
            return item;
          });

          this.grupos = grupos;

          if (this.item.grupo_id) {
            this.selectedGrupo = this.grupos.find(
              (i) => i.id === this.item.grupo_id
            );
          }
          this.loadingGrupos = false;
        })
        .catch(() => {
          this.loadingGrupos = false;
          // console.log(error);
        });
    },

    addGrupo() {
      let grupo = {};
      grupo.descricao = this.buscaGrupo;

      postServicoGrupo(grupo)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Grupo criado com sucesso!");

            this.getGrupos().then(() => {
              this.selectedGrupo = this.grupos.find(
                (i) => i.descricao === grupo.descricao
              );
            });

            this.$refs.grupoBox.isFocused = false;
          }
        })
        .catch(() => {
          // console.log(error);
        });
    },

    updateGrupo(item) {
      let grupo = {};
      grupo.id = item.id;
      grupo.descricao = item.descricao;

      putServicoGrupo(grupo.id, grupo)
        .then((response) => {
          if (response.status === 201) {
            item.edit = false;
            this.$toast.success("Grupo atualizado com sucesso!");
            // this.getGrupos();
          }
        })
        .catch((error) => {
          console.log(error);
          // this.loadingGrupos = false;
        });
    },

    createServico() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        postServico(this.servico)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Serviço criado com sucesso!");
              this.$emit("fetch-servicos");
              this.$emit("update:dialogServico", false);
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              if (error.response.data.descricao) {
                this.$toast.error("Esse serviço já existe!");
              }
            }
            this.loading = false;
          });
      }
    },

    updateServico() {
      if (this.$refs.form.validate()) {
        let servico = { ...this.servico };
        delete servico.grupo;

        this.loading = true;
        putServico(servico.id, servico)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Serviço atualizado com sucesso!");
              this.$emit("fetch-servicos");
              this.$emit("update:dialogServico", false);
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    this.getGrupos();
    if (this.item) {
      this.servico = this.item;
    }
  },
};
</script>

<style lang="scss"></style>
